<template>
  <div>
    <EWBCandidatesPage
      :PageTitle="'Candidates (CPA)'"
      :pageCourse="'CPA'"
    ></EWBCandidatesPage>
  </div>
</template>

<script>
import EWBCandidatesPage from "../components/pagesComponents/EwbCandidatesPage.vue";
import _ from "lodash";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import CandidatesDataTable from "../components/pagesComponents/CandidatesTable.vue";
import Datepicker from "vuejs-datepicker";
// import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
// import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    VTree,
    VSelectTree,
    "v-select": vSelect,
    CandidatesDataTable,
    EWBCandidatesPage
  },
  data() {
    return {
      mwb_names: [],
      // initSelected: ["All"],
      dateRange: {
        startDate: "",
        endDate: ""
      },
      treeData: [
        {
          title: "All",
          expanded: true,
          children: [
            {
              title: "Fees",
              expanded: true,
              children: [
                { title: "Cleared" },
                { title: "Partial" },
                { title: "Default" }
              ]
            },
            {
              title: "Books",
              expanded: true,
              children: [
                { title: "all sent" },
                { title: "Partial" },
                { title: "not sent" }
              ]
            },
            {
              title: "Exams",
              expanded: true,
              children: [
                { title: "Planned for Exams" },
                { title: "Cleared 1" },
                { title: "Cleared all - Alumni" }
              ]
            },
            {
              title: "Evaluation",
              expanded: true,
              children: [{ title: "Done" }, { title: "Yet to initiated" }]
            }
          ]
        }
      ],
      nameAutocomplete: [],
      initSelected: "",
      initSelectedBDE: [],
      initSelectedCourses: [],
      initSelectedLevels: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: []
        }
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA"
            },
            {
              id: "CPA-AA",
              label: "CPA-AA"
            },
            {
              id: "CPA-US",
              label: "CPA-US"
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA"
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA"
            },
            {
              id: "CFA",
              label: "CFA"
            },
            {
              id: "FRM",
              label: "FRM"
            },
            {
              id: "IIMI-BA",
              label: "IIMI-BA"
            },
            {
              id: "IIMI-AA",
              label: "IIMI-AA"
            },
            {
              id: "XLRI-HR",
              label: "XLRI-HR"
            },
            {
              id: "XLRI-SH",
              label: "XLRI-SH"
            },
            // {
            //   id: "RPO",
            //   label: "RPO"
            // },
            {
              id: "IIML-FT",
              label: "IIML-FT"
            },
            {
              id: "USP",
              label: "USP"
            },
            {
              id: "DA",
              label: "DA"
            },
            {
              id: "RPA",
              label: "RPA"
            },
            {
              id: "FOF(O)",
              label: "FOF(O)"
            }
          ]
        }
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3"
                },
                {
                  id: "M3+",
                  label: "M3+"
                },
                {
                  id: "M3++",
                  label: "M3++"
                },
                {
                  id: "M5",
                  label: "M5"
                },
                {
                  id: "M6",
                  label: "M6"
                }
              ]
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2"
                },
                {
                  id: "L3",
                  label: "L3"
                },
                {
                  id: "L4",
                  label: "L4"
                },
                {
                  id: "L6",
                  label: "L6"
                },
                {
                  id: "L5",
                  label: "L5"
                },
                {
                  id: "L3+",
                  label: "L3+"
                }
              ]
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-"
                },
                {
                  id: "M4",
                  label: "M4"
                },
                {
                  id: "M2",
                  label: "M2"
                },
                {
                  id: "M1",
                  label: "M1"
                },
                {
                  id: "L4-",
                  label: "L4-"
                },
                {
                  id: "L1",
                  label: "L1"
                }
              ]
            },
            {
              id: "M7",
              label: "M7"
              // children: []
            },
            {
              id: "N/A",
              label: "N/A"
              // children: []
            },
            {
              id: "DND",
              label: "DND"
              // children: []
            }
          ]
        }
      ],
      selected: "CLEARED",
      currentTablePage: 1,
      EWBCandidates: [],
      tablelinks: 1,
      EWBCandidatesRaw: [],
      tableselect: [],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      searchIn: {
        id: "",
        name: ""
      },
      spoc_city: "",
      rawBde: [],
      rawcities: [],
      infosearch: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0
    };
  },
  mounted() {
    // this.cm2Redirect();
    // this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (localStorage.getItem("role") === "cm4") {
      // this.getSpocAdmin();
    } else {
      // this.getSpoc();
    }
    EventBus.$on("radio-change", val => {
      this.initSelected = val;
    });
    EventBus.$on("open-email-leadsdatatable", payload => {
      this.bulkdata = [];
      for (let y = 1; y <= 5; y++) {
        this.search_API(y, true, payload);
      }
      this.bulkdata.forEach(bulk => {
        console.log(bulk);
      });
    });
    EventBus.$on("reloadEntireData", () => {
      this.getSpoc();
    });
  },
  watch: {
    initSelectedBDE: function() {
      this.mergeAllSearch();
    },
    initSelected: function() {
      this.mergeAllSearch();
    },
    initSelectedCourses: function() {
      this.mergeAllSearch();
    },
    initSelectedLevels: function() {
      this.mergeAllSearch();
    },
    currentTablePage: async function(val) {
      this.analyzePagination(val);
    },
    infosearch: function() {
      this.mergeAllSearch();
    }
  },
  methods: {
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          vm.mwb_names = response.data.map(lead => lead.person_name);
          loading(false);
        });
    }, 350),
    openEmail() {
      this.EmitEvent("open-email-bdeleads");
    },
    removechip(chip) {
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      if (localStorage.getItem("role") === "cm4") {
        this.treeDataBDE[0].children.forEach(child => {
          child.children.forEach(child2 => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach(child => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      this.treeDataCourses[0].children.forEach(child => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach(child => {
        child.children.forEach(chi => {
          if (chi.label === chip) {
            chipPlacement = "levels";
          }
        });
      });
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (localStorage.getItem("role") === "cm4") {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach(child => {
                  child.children.forEach(child2 => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(
                  this.initSelectedBDE.indexOf(city),
                  1
                );
                this.treeDataBDE[0].children.forEach(child => {
                  if (child.label === city) {
                    child.children.forEach(child2 => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach(child => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach(child => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(
              this.initSelectedCourses.indexOf(chip),
              1
            );
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach(child => {
              child.children.forEach(chi => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(chip),
              1
            );
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach(child => {
              child.children.forEach(chi => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach(child => {
              if (child.label === childProperty) {
                child.children.forEach(chi => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                  }
                });
              }
            });
          }
      }
    },
    analyzePagination(val) {
      if (this.currentTablePage === 1) {
        this.search_API(1);
      } else {
        this.search_API(val);
      }
    },
    // async search_API(page, copyfor) {
    //   let focus = this.initSelected;
    //   let levels = [];
    //   let bde = [];
    //   let courses = [];
    //   if (localStorage.getItem("role") === "cm4") {
    //     this.initSelectedBDE.forEach(sort => {
    //       if (sort === "All") {
    //         this.treeDataBDE[0].children.forEach(child => {
    //           child.children.forEach(acutal_spoc => {
    //             bde.push(acutal_spoc.label);
    //           });
    //         });
    //       } else {
    //         let city = false;
    //         this.treeDataBDE[0].children.forEach(child => {
    //           if (child.label === sort) {
    //             child.children.forEach(acutal_spoc => {
    //               bde.push(acutal_spoc.label);
    //             });
    //             city = true;
    //           }
    //         });
    //         if (city) {
    //           return true;
    //         } else {
    //           bde.push(sort);
    //         }
    //       }
    //     });
    //   } else {
    //     this.initSelectedBDE.forEach(sort => {
    //       if (sort === "All") {
    //         this.treeDataBDE[0].children.forEach(child => {
    //           bde.push(child.label);
    //         });
    //       } else {
    //         bde.push(sort);
    //       }
    //     });
    //   }
    //   // console.log(bde);
    //   // return;
    //   this.initSelectedCourses.forEach(sort => {
    //     if (sort === "All") {
    //       this.treeDataCourses[0].children.forEach(child => {
    //         courses.push(child.label);
    //       });
    //     } else {
    //       courses.push(sort);
    //     }
    //   });
    //   this.initSelectedLevels.forEach(sort => {
    //     if (sort === "All") {
    //       this.treeDataLevels[0].children[0].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //       this.treeDataLevels[0].children[1].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //       this.treeDataLevels[0].children[2].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //     } else if (sort === "P1") {
    //       this.treeDataLevels[0].children[0].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //     } else if (sort === "P2") {
    //       this.treeDataLevels[0].children[1].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //     } else if (sort === "P3") {
    //       this.treeDataLevels[0].children[2].children.forEach(child => {
    //         levels.push(child.label);
    //       });
    //     } else {
    //       levels.push(sort);
    //     }
    //   });
    //   let bdeid = [];
    //   bde.forEach(spoc => {
    //     this.rawBde.forEach(raw => {
    //       if (raw.first_name === spoc) {
    //         bdeid.push(raw.id);
    //       }
    //     });
    //   });
    //   let visitors = "";
    //   let net_enquiry = "";
    //   let eligibility = "";
    //   let reference = "";
    //   let loan = "";
    //   let corporate = "";
    //   let university = "";
    //   this.infosearch.forEach(value => {
    //     switch (value) {
    //       case "Visitors":
    //         visitors = "Yes";
    //         break;
    //       case "Net Enquiry":
    //         net_enquiry = "Yes";
    //         break;
    //       case "Eligibility":
    //         eligibility = "Yes";
    //         break;
    //       case "Reference":
    //         reference = "Yes";
    //         break;
    //       case "Loan":
    //         loan = "Yes";
    //         break;
    //       case "Corporate":
    //         corporate = "Yes";
    //         break;
    //       case "University":
    //         university = "Yes";
    //         break;
    //     }
    //   });
    //   let searchIdentity = "";
    //   if (this.value5 !== "") {
    //     searchIdentity = `${this.spoc_city.charAt(0)}-${this.value5}`;
    //   }
    //   let obj = {
    //     courses: courses.join(),
    //     levels: levels.join(),
    //     my_focus: focus,
    //     spoc_ids: bdeid.join(),
    //     name: this.value1,
    //     identity: this.value5,
    //     company: this.value2,
    //     mobile: this.value3,
    //     email: this.value4,
    //     visitors: visitors,
    //     net_enquiry: net_enquiry,
    //     eligibility: eligibility,
    //     reference: reference,
    //     loan: loan,
    //     corporate: corporate,
    //     university: university,
    //     from_date: "",
    //     to_date: ""
    //   };
    //   if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
    //     obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
    //     obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
    //   }
    //   console.log(obj);
    //   if (copyfor === true) {
    //     axios
    //       .post(
    //         `${constants.SERVER_API}getSearchedMwbLeads?page=${page}`,
    //         obj,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.userAccessToken}`
    //           }
    //         }
    //       )
    //       .then(response => {
    //         response.data.data.forEach(lead => {
    //           if (this.bulkdata.length !== 50) {
    //             this.bulkdata.push(lead);
    //           }
    //         });
    //       })
    //       .catch(error => {
    //         this.handleError(error);
    //       });
    //   } else {
    //     axios
    //       .post(
    //         `${constants.SERVER_API}getSearchedMwbLeads?page=${page}`,
    //         obj,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.userAccessToken}`
    //           }
    //         }
    //       )
    //       .then(response => {
    //         response.data.data.forEach(lead => {
    //           lead.engagements = [];
    //           lead.mobile = [];
    //           lead.emails = [];
    //         });
    //         this.currentTablePage = response.data.current_page;
    //         this.tablelinks = response.data.last_page;
    //         this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
    //       })
    //       .catch(error => {
    //         this.handleError(error);
    //       });
    //   }
    // },
    /* async search_API(page, copyfor) {
      let focus = this.initSelected;
      let levels = [];
      let bde = [];
      let courses = [];
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      // console.log(bde);
      // return;
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach(spoc => {
        this.rawBde.forEach(raw => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let visitors = "";
      let net_enquiry = "";
      let eligibility = "";
      let reference = "";
      let loan = "";
      let corporate = "";
      let university = "";
      this.infosearch.forEach(value => {
        switch (value) {
          case "Visitors":
            visitors = "Yes";
            break;
          case "Net Enquiry":
            net_enquiry = "Yes";
            break;
          case "Eligibility":
            eligibility = "Yes";
            break;
          case "Reference":
            reference = "Yes";
            break;
          case "Loan":
            loan = "Yes";
            break;
          case "Corporate":
            corporate = "Yes";
            break;
          case "University":
            university = "Yes";
            break;
        }
      });
      let searchIdentity = "";
      if (this.value5 !== "") {
        let searchIdentity = `${this.spoc_city.charAt(0)}-${this.value5}`;
      }
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: focus,
        spoc_ids: bdeid.join(),
        name: this.value1,
        identity: this.value5,
        company: this.value2,
        mobile: this.value3,
        email: this.value4,
        visitors: visitors,
        net_enquiry: net_enquiry,
        eligibility: eligibility,
        reference: reference,
        loan: loan,
        corporate: corporate,
        university: university,
        from_date: "",
        to_date: ""
      };
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      console.log(obj);
      if (copyfor === true) {
        axios
          .post(
            `${constants.SERVER_API}getSearchedCpaLeads?page=${page}`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`
              }
            }
          )
          .then(response => {
            response.data.data.forEach(lead => {
              if (this.bulkdata.length !== 50) {
                this.bulkdata.push(lead);
              }
            });
          })
          .catch(error => {
            this.handleError(error);
          });
      } else {
        
        axios
          .post(
            `${constants.SERVER_API}getSearchedCpaLeads?page=${page}`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`
              }
            }
          )
          .then(response => {
            response.data.data.forEach(lead => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
              lead.cpa.latest_engagement = lead.mwb;
            });
            console.log('baba', response.data.data);
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);

          })
          .catch(error => {
            this.handleError(error);
          });
      }
    }, */
    async search_API(page, copyfor) {
      let focus = this.initSelected;
      let levels = [];
      let bde = [];
      let courses = [];
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      // console.log(bde);
      // return;
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach(spoc => {
        this.rawBde.forEach(raw => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let visitors = "";
      let net_enquiry = "";
      let eligibility = "";
      let reference = "";
      let loan = "";
      let corporate = "";
      let university = "";
      this.infosearch.forEach(value => {
        switch (value) {
          case "Visitors":
            visitors = "Yes";
            break;
          case "Net Enquiry":
            net_enquiry = "Yes";
            break;
          case "Eligibility":
            eligibility = "Yes";
            break;
          case "Reference":
            reference = "Yes";
            break;
          case "Loan":
            loan = "Yes";
            break;
          case "Corporate":
            corporate = "Yes";
            break;
          case "University":
            university = "Yes";
            break;
        }
      });
      // let searchIdentity = "";
      // if (this.value5 !== "") {
      //   searchIdentity = `${this.spoc_city.charAt(0)}-${this.value5}`;
      // }
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: focus,
        spoc_ids: bdeid.join(),
        name: this.value1,
        identity: this.value5,
        company: this.value2,
        mobile: this.value3,
        email: this.value4,
        visitors: visitors,
        net_enquiry: net_enquiry,
        eligibility: eligibility,
        reference: reference,
        loan: loan,
        corporate: corporate,
        university: university,
        from_date: "",
        to_date: ""
      };
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      console.log("my params", obj);

      if (copyfor === true) {
        axios
          .get(`${constants.SERVER_API}getSearchedCpaLeads?page=${page}`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`
            }
          })
          .then(response => {
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            console.log(response.data.data);
            response.data.data.forEach(lead => {
              if (this.bulkdata.length !== 50) {
                this.bulkdata.push(lead);
              }
            });
          })
          .catch(error => {
            this.handleError(error);
          });
      } else {
        /* alert(localStorage.userAccessToken);
        return; */
        axios
          .post(
            `${constants.SERVER_API}getSearchedCpaLeads?page=${page}`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`
              }
            }
          )
          .then(response => {
            console.log(response);
            response.data.data.forEach(lead => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            this.$store.commit("MOUNT_CPA_LEADS", response.data.data);
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      // this.infosearch = [];
      // this.initSelectedBDE = [];
      // this.initSelectedCourses = [];
      // this.initSelectedLevels = [];
      // this.dateRange.startDate = "";
      // this.dateRange.endDate = "";
      this.getSpoc();
    },
    getSpoc() {
      // this.initSelectedBDE = [];
      // let url = `${constants.SERVER_API}getSpocs`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.rawBde = [];
      //     let usercity = "";
      //     this.searchIn.id = 75;
      //     this.searchIn.name = response.data.logged_in_user_name;
      //     response.data.spocs.forEach(spoc => {
      //       if (spoc.id === this.searchIn.id) {
      //         usercity = spoc.city;
      //         this.spoc_city = spoc.city;
      //       }
      //     });
      //     response.data.spocs.forEach(spoc => {
      //       if (spoc.city === usercity) {
      //         this.rawBde.push(spoc);
      //       }
      //     });
      //     this.treeDataBDE[0].children = [];
      //     this.rawBde.forEach(data => {
      //       var obj = {
      //         id: data.first_name,
      //         label: data.first_name
      //       };
      //       this.treeDataBDE[0].children.push(obj);
      //     });
      //     if (localStorage.getItem("role") === "cm1") {
      //       this.initSelectedBDE.push(this.searchIn.name);
      //     }
      //     this.search_API(1);
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          // this.treeDataBDE[0].children = [];
          // response.data.spocs.forEach(data => {
          //   var obj = {
          //     id: data.first_name,
          //     label: data.first_name
          //   };
          //   this.treeDataBDE[0].children.push(obj);
          // });
          // this.initSelectedBDE.push(this.searchIn.name);
          // this.initialFetchCandidates(1);
          this.search_API(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          console.log(response);
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map(name => {
              return {
                count: 1,
                name: name.city
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: []
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach(server_spoc => {
            this.treeDataBDE.forEach(bde => {
              bde.children.forEach(bdechid => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          // if (this.rawBde.indexOf(this.searchIn) !== -1) {
          //   this.initSelectedBDE.push(this.searchIn.name);
          // } else {
          //   this.initSelectedBDE.push("All");
          // }
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          } else {
            this.initSelectedBDE.push(this.treeDataBDE[0].children[0].label);
          }
          this.search_API(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    initialFetchCandidates(currentTablePage) {
      let obj = {
        spoc_ids: "",
        page: currentTablePage
      };
      obj.spoc_ids = this.searchIn.id;
      // obj.spoc_ids = 75;
      axios
        .get(`${constants.SERVER_API}getCpaCandidates`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log("candiadtes data", response.data);
          this.mountLeadsToStore(response);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    mountLeadsToStore(response) {
      response.data.data.forEach(lead => {
        // lead.cpa.classes_attended_aug = lead.attendance.aud;
        // lead.cpa.classes_attended_bec = lead.attendance.bec;
        // lead.cpa.classes_attended_far = lead.attendance.far;
        // lead.cpa.engagement_details = lead.latest_engagement.details;
        // lead.cpa.engagement_add_on = lead.latest_engagement.created_at;
        lead.engagements = [];
        lead.mobile = [];
        lead.emails = [];
        // lead.cpa.mwb = [];
        // lead = lead.cpa;
        lead.cpa.latest_engagement = lead.mwb;
      });
      this.currentTablePage = response.data.current_page;
      this.tablelinks = response.data.last_page;
      this.$store.commit("MOUNT_CPA_LEADS", response.data.data);
    },
    mergeAllSearch() {
      // alert("Hello");
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (localStorage.getItem("role") === "cm4") {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              child.children.forEach(acutal_spoc => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach(child => {
              if (child.label === sort) {
                child.children.forEach(acutal_spoc => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach(sort => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach(child => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach(sort => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "") {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach(info => {
        this.searchCriteria.push(info);
      });
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    search() {
      this.search_API();
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      // console.log("clicked");
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise(resolve => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" }
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      // console.log("clicked");
      return this.treeDataBDE;
    },
    consolelog() {
      // console.log("clicked");
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    }
  }
};
</script>

<style lang="scss">
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
</style>
