<template>
  <div>
    <vs-row>
      <vs-col>
        <vs-table multiple v-model="tableselect" max-items="10" :data="TableData">
          <template slot="thead">
            <vs-th>STATUS</vs-th>
            <vs-th>Can-ID</vs-th>
            <vs-th sort-key="candidate_name">NAME</vs-th>
            <vs-th sort-key="batch">BATCH</vs-th>
            <vs-th sort-key="enrollment_date">ENROLLMENT DATE</vs-th>
            <vs-th>COMPANY</vs-th>
            <vs-th >NEXT CALL</vs-th>
            <vs-th >LAST CALL</vs-th>
            <vs-th>AGREEMENT STATUS</vs-th>
            <vs-th>EMAIL SENT STATUS</vs-th>
            <!-- <vs-th>PAYMENT STATUS</vs-th> -->
            <vs-th>VIEW INFO</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody ref="tbody">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-row>
                    <!-- <vx-tooltip :text="'Fees: ' +tr.fees.collection_status" position="left">
                      <span>
                        <vs-icon
                          icon="attach_money"
                          :color="getFeesColor(tr.fees.collection_status)"
                        ></vs-icon>
                      </span>
                    </vx-tooltip> -->
                    <vx-tooltip :text="'Books'" position="left" v-if="tr.sr_data">
                      <span>
                        <vs-icon icon="book"></vs-icon>
                      </span>
                    </vx-tooltip>
                    <vx-tooltip :text="'Candidate Status: ' +getExamText(tr.sr_data)" v-if="tr.sr_data" position="top">
                      <span>
                        <vs-icon icon="grade" :color="getExamColor(tr.sr_data)"></vs-icon>
                      </span>
                    </vx-tooltip>
                    <vx-tooltip
                    v-if="tr.sr_data"
                      :text="'Evaluation: ' +getEvaluationText(tr.sr_data.evaluation)"
                      position="top"
                    >
                      <span>
                        <vs-icon icon="assignment" :color="getEvaluationColor(tr.sr_data.evaluation)"></vs-icon>
                      </span>
                    </vx-tooltip>
                    <vx-tooltip v-if="tr.sr_data" :text="'NTS: ' +getNtsText(tr.sr_data.nts)" position="right">
                      <span>
                        <vs-icon icon="date_range" :color="getNtsColor(tr.sr_data.nts)"></vs-icon>
                      </span>
                    </vx-tooltip>
                  </vs-row>
                </vs-td>
                  <vs-td>
                  <div>{{tr.mwb.identity}}</div>
                </vs-td>

                <vs-td>
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="12">
                      <!-- <vx-tooltip
                        :title="tr.mwb.spoc_name"
                        :text="tr.mwb.engagement_details"
                      ></vx-tooltip> -->
                      {{tr.enrollment.person_name}}
                    </vs-col>
                  </vs-row>
                </vs-td>
                <!-- <vs-td>
                    <div>
                      {{tr.cpa.person_name}}
                    </div>
                </vs-td>-->

                <vs-td>
                  <div>{{tr.enrollment.batch}}</div>
                </vs-td>

                <vs-td>
                  <div>{{unixTimestampConvert(tr.enrollment.enrollment_date)}}</div>
                </vs-td>

                <vs-td>{{tr.mwb.company}}</vs-td>


                <vs-td>{{unixTimestampConvert(getRoleNextCall(tr.mwb))}}</vs-td>


                <vs-td>{{unixTimestampConvert( getRoleLastCall(tr.mwb))}}</vs-td>

                
                <vs-td>
                  <!-- {{getInfo(tr)}} -->
                  <div>{{ getAgreementStatus(tr.enrollment) }}</div>
                </vs-td>

                <vs-td>
                  <div>{{ getEmailStatus(tr.agreement) }}</div>
                </vs-td>

                <!-- <vs-td>
                  <div>{{ getPaymentStatus(tr.student_subjects) }}</div>
                </vs-td> -->

                <vs-td>
                  <vs-row>
                    <!-- <img
                      @click="openPopupEmit(tr)"
                      src="https://img.icons8.com/material-outlined/24/000000/info.png"
                    />
                    <img
                      @click="additional('open-student-details-popup-call', tr)"
                      src="https://img.icons8.com/ios-glyphs/24/000000/phone.png"
                    />
                    <img
                      @click="EmitEvent('open-email-engagement', tr)"
                      src="https://img.icons8.com/windows/24/000000/gmail.png"
                    />
                    <img
                      @click="additional('open-student-details-popup-whatsapp', tr)"
                      src="https://img.icons8.com/material-rounded/24/000000/chat.png"
                    />-->
                    <div @click="openPopupEmit(tr)">
                      <vs-icon icon="info_outline" size="small" color="dark"></vs-icon>
                    </div>
                    <div @click="Resendmail(tr)">
                      <img src="https://img.icons8.com/android/24/000000/forward-arrow.png" />
                    </div>
                    <!-- <div @click="EmitEvent('open-email-engagement', tr)">
                  <vs-icon icon="email" size="small" color="dark"></vs-icon>
                </div>
                <div @click="additional('open-student-details-popup-whatsapp', tr)">
                  <vs-icon icon="chat" size="small" color="dark"></vs-icon>
                    </div>-->
                  </vs-row>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
    <!-- <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row> -->
  </div>
</template>

<script>
// import _ from "lodash";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import EventBus from "../eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import moment from "moment";
import constants from "../../../constants.json";

export default {
  props: ["totalpagination", "TableData"],
  data() {
    return {
      mwb_names: [],
      initSelected: ["All"],
      treeData: [
        {
          title: "All",
          expanded: true,
          children: [
            {
              title: "Fees",
              expanded: true,
              children: [
                { title: "Cleared" },
                { title: "Partial" },
                { title: "Default" }
              ]
            },
            {
              title: "Books",
              expanded: true,
              children: [
                { title: "all sent" },
                { title: "Partial" },
                { title: "not sent" }
              ]
            },
            {
              title: "Exams",
              expanded: true,
              children: [
                { title: "Planned for Exams" },
                { title: "Cleared 1" },
                { title: "Cleared all - Alumni" }
              ]
            },
            {
              title: "Evaluation",
              expanded: true,
              children: [{ title: "Done" }, { title: "Yet to initiated" }]
            }
          ]
        }
      ],
      selected: "CLEARED",
      currentTablePage: 1,
      EWBCandidates: [],
      tablelinks: 1,
      EWBCandidatesRaw: [],
      tableselect: [],
      value1: "",
      value2: "",
      value3: "",
      value5: "",
      searchIn: {
        id: "",
        name: ""
      },
      spoc_city: "",
      rawBde: [],
      rawcities: [],
      infosearch: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0
    };
  },
  components: {
    VTree,
    VSelectTree,
    "v-select": vSelect
  },
  watch: {
    initSelected: function(value) {
      this.EWBCandidates = [];
      // console.log(this.EWBCandidatesRaw);
      // console.log(value);
      value.forEach(selected => {
        if (
          selected === "Cleared" ||
          selected === "Partial" ||
          selected === "Default"
        ) {
          this.EWBCandidatesRaw.forEach(candidate => {
            if (candidate.fees_status === selected) {
              if (this.EWBCandidates.includes(candidate)) {
                console.log("exists");
              }
              this.EWBCandidates.push(candidate);
            }
          });
        }
        if (
          selected === "Cleared" ||
          selected === "Partial" ||
          selected === "Default"
        ) {
          this.EWBCandidatesRaw.forEach(candidate => {
            if (candidate.book_status === selected) {
              this.EWBCandidates.push(candidate);
            }
          });
        }
        if (
          selected === "Planned for exams" ||
          selected === "Cleared 1" ||
          selected === "Cleared all - Alumni"
        ) {
          this.EWBCandidatesRaw.forEach(candidate => {
            if (candidate.candidate_status === selected) {
              this.EWBCandidates.push(candidate);
            }
          });
        }
        if (selected === "Done" || selected === "Not yet initiated") {
          this.EWBCandidatesRaw.forEach(candidate => {
            if (candidate.evaluations === selected) {
              this.EWBCandidates.push(candidate);
            }
          });
        }
      });
    },
    currentTablePage(value) {
      this.initialFetchCandidates(value);
    }
  },
  mounted() {
    // this.$store.state.allCandidatesRaw.forEach((candid) => {
    //   if (candid.category === 'ewb') {
    //     this.EWBCandidates.push(candid)
    //     this.EWBCandidatesRaw.push(candid)
    //   }
    // })
    // this.getSpoc();
    // this.initialFetchCandidates()
  },
  methods: {
    getPaymentStatus(student_subjects) {
      let status = 'No Payment';

      if (student_subjects.length > 0) {
        switch (student_subjects[0].payment_status) {
        case 1:
          status = 'Partial Payment'
          break;
        case 2:
          status = 'Full Payment';
          break
      }
      }
      return status;
    },
    getlastCall(tr) {
      if (this.getrole("cm1")) {
      return tr.last_call;
      } else if (this.getrole("ac1") || this.getrole("ac2")) {
        return tr.acads_last_call;
      } else if (this.getrole("exed") || this.getrole("exed2")) {
        return tr.iiml_last_call;
      }
    },
    getnextCall(tr) {
      // console.log(tr)
      if (this.getrole("cm1")) {
      return tr.next_call;
      } else if (this.getrole("ac1") || this.getrole("ac2")) {
        return tr.acads_next_call;
      } else if (this.getrole("exed") || this.getrole("exed2")) {
        return tr.iiml_next_call;
      }
    },
    getEmailStatus(agreement) {
      let returnValue = "";
      if (agreement !== null) {
        switch (agreement.sent_status) {
          case 0:
            returnValue = "Not Sent";
            break
          case 1:
            returnValue = "Sent";
        }
        return returnValue;
      } else {
        return "old Data";
      }
    },
    getAgreementStatus(enrollment) {
      let returnValue = "";
      if (enrollment !== null) {
        switch (enrollment.agreed_status) {
        case 0:
          returnValue =  "Incomplete";
          break;
        case 1:
          returnValue =  "Complete";
      } 
      return returnValue 
      } else {
        return 'old Data'
      }
    },
    convertTimestampToDate(timestamp) {
      if (timestamp !== "") {
        let date = moment.unix(timestamp).format("DD-MM-YYYY");
        return date;
      }
      return "";
    },
    getSpoc() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
        })
        .then(response => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBde = response.data.spocs;
          // this.treeDataBDE[0].children = [];
          // response.data.spocs.forEach(data => {
          //   var obj = {
          //     id: data.first_name,
          //     label: data.first_name
          //   };
          //   this.treeDataBDE[0].children.push(obj);
          // });
          // this.initSelectedBDE.push(this.searchIn.name);
          this.initialFetchCandidates(1);
          // this.search_API(1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    initialFetchCandidates(currentTablePage) {
      this.$vs.loading();
      let obj = {
        spoc_ids: "",
        page: currentTablePage
      };
      obj.spoc_ids = this.searchIn.id;
      // obj.spoc_ids = 75;
      axios
        .get(`${constants.SERVER_API}getCpaCandidates`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`
          }
        })
        .then(response => {
          console.log("candiadtes data", response.data);
          this.mountLeadsToStore(response);
          this.$vs.loading.close();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    mountLeadsToStore(response) {
      response.data.data.forEach(lead => {
        // lead.cpa.classes_attended_aug = lead.attendance.aud;
        // lead.cpa.classes_attended_bec = lead.attendance.bec;
        // lead.cpa.classes_attended_far = lead.attendance.far;
        // lead.cpa.engagement_details = lead.latest_engagement.details;
        // lead.cpa.engagement_add_on = lead.latest_engagement.created_at;
        lead.engagements = [];
        lead.mobile = [];
        lead.emails = [];
        // lead.cpa.mwb = [];
        // lead = lead.cpa;
        lead.cpa.latest_engagement = lead.mwb;
      });
      this.currentTablePage = response.data.current_page;
      this.tablelinks = response.data.last_page;
      this.$store.commit("MOUNT_EWB_LEADS", response.data.data);
    },
    additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
    Resendmail(tr) {
      EventBus.$emit("open-CpaEmail", tr);
    },
    openPopupEmit(payload) {
      // console.log("the payload", payload);
      this.openBigPopup(payload.enrollment.mwb_id)
      // this.$vs.loading();
      // let url = `${constants.SERVER_API}getMwbDetailsForEwb?person_id=${payload.cpa.person_id}&mwb_id=${payload.cpa.mwb_id}`;
      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
      //   })
      //   .then(response => {
      //     this.$store.commit("UPDATE_EWB_NEW_DATA", [
      //       payload,
      //       response.data.engagements,
      //       response.data.mobile,
      //       response.data.emails,
      //       response.data.mwb
      //     ]);
      //     EventBus.$emit("open-student-report-popup", payload);
      //     this.$vs.loading.close();
      //   })
      //   .catch(error => {
      //     this.handleError(error);
      //   });
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      console.log("clicked");
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise(resolve => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" }
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      alert("hep");
    },
    eval(ev) {
      switch (ev) {
        case "":
          return "#F1C40F";
        case "Done":
          return "#2ECC71";
        case "Not yet initiated":
          return "#e74c3c";
      }
    },
    pushCondition(user) {
      this.EWBCandidates.forEach(ex => {
        if (user.id !== ex.id) {
          this.EWBCandidates.push(user);
        }
      });
    },
    generateStar(candidate_status, evaluations) {
      if (candidate_status === "Drop-out") {
        return "#E74C3C";
      } else if (
        candidate_status === "Planned for exams" &&
        evaluations === "Not yet initiated"
      ) {
        return "#F1C40F";
      } else if (candidate_status === "Status Unknown?") {
        return "#CFCFCF";
      } else if (
        candidate_status === "Planned for exams" &&
        evaluations === "Done"
      ) {
        return "#F1C40F";
      } else if (candidate_status === "Cleared 1" && evaluations === "Done") {
        return "#2ECC71";
      } else if (candidate_status === "Cleared all - Alumni") {
        return "#F1C40F";
      }
    },
    line(candidate_status, evaluations) {
      if (candidate_status === "Drop-out") {
        return "";
      } else if (
        candidate_status === "Planned for exams" &&
        evaluations === "Not yet initiated"
      ) {
        return "";
      } else if (
        candidate_status === "Planned for exams" &&
        evaluations === "Done"
      ) {
        return "";
      } else if (candidate_status === "Cleared 1" && evaluations === "Done") {
        return "";
      } else if (candidate_status === "Cleared all - Alumni") {
        return "#F1C40F";
      }
    },
    feesColor(Fees_Status) {
      switch (Fees_Status) {
        case "Cleared":
          return "success";
        case "Default":
          return "danger";
        case "Partial":
          return "warning";
        default:
          return "danger";
      }
    },
    booksColor(book_status) {
      switch (book_status) {
        case "Cleared":
          return "#2ECC71";
        case "partial":
          return "#F1C40F";
        case "Default":
          return "#E74C3C";
        default:
          return "#E74C3C";
      }
    },
    sortEmit(payload) {
      this.selected = payload.toUpperCase();
      this.$store.commit("SORT_CANDIDATE_TABLE", payload);
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    // colors and text for lighter icons
    getNtsColor(nts_status) {
      let color = "black";
      if (nts_status === "Done") {
        color = "green";
      } else if (nts_status === "WIP") {
        color = "orange";
      } else if (nts_status === "Not yet initiated") {
        color = "red";
      } else {
        color = "grey";
      }
      return color;
    },
    getNtsText(nts_status) {
      let txt = nts_status;
      if (
        nts_status === undefined ||
        nts_status === "" ||
        nts_status === null
      ) {
        txt = "Unknown";
      }
      return txt;
    },
    getEvaluationText(evaluation_status) {
      let txt = evaluation_status;
      if (
        evaluation_status === undefined ||
        evaluation_status === "" ||
        evaluation_status === null
      ) {
        txt = "Unknown";
      }
      return txt;
    },
    getEvaluationColor(evaluation_status) {
      let color = "black";
      if (evaluation_status === "Done") {
        color = "green";
      } else if (evaluation_status === "WIP") {
        color = "orange";
      } else if (evaluation_status === "Not yet initiated") {
        color = "red";
      } else if (evaluation_status === "Awaiting PGDPA") {
        color = "lightblue";
      } else {
        color = "grey";
      }
      return color;
    },

    getBooksText(books) {
      if (!books.hasOwnProperty("aud")) {
        return "Not Initiated";
      } else if (
        books.aud != "" &&
        books.far != "" &&
        books.bec != "" &&
        books.reg_1 != "" &&
        books.reg_2 != ""
      ) {
        return "Cleared";
      } else if (
        books.aud != "" ||
        books.far != "" ||
        books.bec != "" ||
        books.reg_1 != "" ||
        books.reg_2 != ""
      ) {
        return "Partial";
      } else {
        return "Due";
      }
    },
    getBooksColor(books) {
      if (!books.hasOwnProperty("aud")) {
        return "lightgrey";
      } else if (
        books.aud != "" &&
        books.far != "" &&
        books.bec != "" &&
        books.reg_1 != "" &&
        books.reg_2 != ""
      ) {
        return "green";
      } else if (
        books.aud != "" ||
        books.far != "" ||
        books.bec != "" ||
        books.reg_1 != "" ||
        books.reg_2 != ""
      ) {
        return "orange";
      } else {
        return "red";
      }
    },

    getFeesColor(fee_collection_status) {
      let color = "black";
      if (fee_collection_status === "Cleared") {
        color = "green";
      } else if (fee_collection_status === "Due") {
        color = "orange";
      } else if (fee_collection_status === "Default") {
        color = "red";
      } else {
        color = "grey";
      }
      return color;
    },

    getExamText(cpa) {
      if (!cpa.hasOwnProperty("aud") || cpa.aud == null) {
        return "Not Initiated";
      } else if (
        cpa.aud == "Cleared" &&
        cpa.far == "Cleared" &&
        cpa.bec == "Cleared" &&
        cpa.reg == "Cleared"
      ) {
        return "Cleared all - Alumni";
      } else if (
        cpa.aud == "Cleared" ||
        cpa.far == "Cleared" ||
        cpa.bec == "Cleared" ||
        cpa.reg == "Cleared"
      ) {
        return "Cleared some - WIP";
      } else if (
        cpa.aud == "Drop-out" ||
        cpa.far == "Drop-out" ||
        cpa.bec == "Drop-out" ||
        cpa.reg == "Drop-out"
      ) {
        return "Drop-out";
      } else if (
        cpa.aud == "" &&
        cpa.far == "" &&
        cpa.bec == "" &&
        cpa.reg == ""
      ) {
        return "Status Unknown";
      } else {
        return "Planned for Exams";
      }
    },
    getExamColor(cpa) {
      if (!cpa.hasOwnProperty("aud") || cpa.aud == null) {
        return "lightgrey";
      } else if (
        cpa.aud == "Cleared" &&
        cpa.far == "Cleared" &&
        cpa.bec == "Cleared" &&
        cpa.reg == "Cleared"
      ) {
        return "green";
      } else if (
        cpa.aud == "Cleared" ||
        cpa.far == "Cleared" ||
        cpa.bec == "Cleared" ||
        cpa.reg == "Cleared"
      ) {
        return "orange";
      } else if (
        cpa.aud == "Drop-out" ||
        cpa.far == "Drop-out" ||
        cpa.bec == "Drop-out" ||
        cpa.reg == "Drop-out"
      ) {
        return "red";
      } else if (
        cpa.aud == "" &&
        cpa.far == "" &&
        cpa.bec == "" &&
        cpa.reg == ""
      ) {
        return "black";
      } else {
        return "blue";
      }
    }
  }
};
</script>

<style lang="scss">