<template>
  <div>
    <vx-card actionable class="cardx" :title="PageTitle">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="dropdown-login customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu
              class="dropdown-login customDropDown"
              style="padding: 0px"
            >
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
              <!-- <treeselect v-model="initSelected" :options="treeDatafocus" /> -->
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Levels
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedLevels"
                :multiple="true"
                :options="treeDataLevels"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="1.5"
        >
          <label>Next Call</label>
        </vs-col>
         <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <date-range-picker
            ref="picker"
            :opens="'right'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="nextCallRange"
            style="min-width:60%"
            @update="analyzePagination(1)"
          ></date-range-picker>
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Courses
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedCourses"
                :multiple="true"
                :options="treeDataCourses"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="2"
        >
          <label>Enrollment Date</label>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="min-width:60%"
            @update="analyzePagination(1)"
          ></date-range-picker>
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1.5">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button
              radius
              color="dark"
              type="gradient"
              icon="search"
            ></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-row vs-w="12" style="margin-bottom: 5%" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <!-- <v-select
                    :filterable="false"
                    :options="mwb_names"
                    @search="autoCompleteName"
                    placeholder="Name"
                    v-model="value1"
                    style="width:100%"
                  /> -->
                  <vs-input
                    type="text"
                    label-placeholder="Name"
                    v-model="value1"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('name')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    :filterable="true"
                    :options="batch_code_categories"
                    placeholder="Batch Code"
                    v-model="batch_code_category"
                    style="width:100%"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('name')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Can-Id"
                    v-model="value5"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('canId')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Company"
                    v-model="value2"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('company')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Designation"
                    v-model="designation_search"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('designation')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Mobile"
                    v-model="value3"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('mobile')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Email"
                    v-model="value4"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('email')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 5%" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Comments"
                    v-model="comments"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('comments')"
                  ></vs-button>
                </vs-col>
              </vs-row>

              <vs-row vs-w="12" style="margin-bottom: 5%" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    class="w-full"
                    placeholder="Agreement Status"
                    v-model="agreement_sent_status"
                    :options="['Incomplete', 'Complete']"
                    label="text"
                  ></v-select>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('email')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 5%" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <v-select
                    class="w-full"
                    placeholder="Email Sent Status"
                    v-model="email_sent_status"
                    :options="['Sent', 'Not Sent']"
                    label="text"
                  ></v-select>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('comments')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <vs-input
                    type="text"
                    label-placeholder="Batch Number"
                    v-model="batch_number"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('batch_number')"
                  ></vs-button>
                </vs-col>
              </vs-row> -->
              <!-- <vs-row vs-w="12" class="searchGap">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="9"
                >
                  <date-range-picker
                    ref="picker"
                    :opens="'left'"
                    :locale-data="{
                      firstDay: 1,
                      format: 'DD-MM-YY',
                      customRangeLabel: 'Custom Range'
                    }"
                    :minDate="null"
                    :maxDate="null"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="true"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="true"
                    v-model="nextCall"
                    style="width: 200px; margin-left:2%"
                  >
                    <template v-slot:input="picker" style="min-width: 350px;">
                    </template>
                  </date-range-picker>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('batch_number')"
                  ></vs-button>
                </vs-col>
              </vs-row> -->
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- <vs-dropdown class="gap" vs-custom-content vs-trigger-click>
            <vs-button radius class="gap" color="dark" type="gradient" icon="info"></vs-button>
            <vs-dropdown-menu class="loginx">
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Net Enquiry">
                <b>Net Enquiry</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="University">
                <b>University</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Corporate">
                <b>Corporate</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Loan">
                <b>Loan</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Eligibility">
                <b>Eligibility</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Visitors">
                <b>Visitors</b>
              </vs-checkbox>
              <vs-checkbox style="margin: 2%" v-model="infosearch" vs-value="Reference">
                <b>Reference</b>
              </vs-checkbox>
            </vs-dropdown-menu>
          </vs-dropdown> -->
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
        >
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="analyzePagination(1)"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col>
          <data-table
            v-if="pageCourse === 'CPA'"
            :totalpagination="tablelinks"
            :TableData="TableData"
          />
          <cma-data-table
            v-if="pageCourse === 'CMA'"
            :totalpagination="tablelinks"
            :TableData="TableData"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination
            :total="tablelinks"
            v-model="currentTablePage"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import dataTable from "./CandidatesTable.vue";
import CmadataTable from "./CandidatesCmaTable.vue";
import DateRangePicker from "vue2-daterange-picker";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import EventBus from "../eventbus";
import axios from "axios";
import constants from "../../../constants.json";
import _ from "lodash";
import vSelect from "vue-select";

export default {
  props: {
    PageTitle: {
      type: String,
      required: true,
    },
    pageCourse: {
      type: String,
      required: true,
    },
  },
  components: {
    DateRangePicker,
    Treeselect,
    "v-select": vSelect,
    dataTable,
    "cma-data-table": CmadataTable,
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.generateBatchDropdown();
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "fi1" ||
      localStorage.getItem("role") === "wc" ||
      localStorage.getItem("role") === "ac2" ||
      localStorage.getItem("role") === "ac1" ||
      localStorage.getItem("role") === "fe" ||
      localStorage.getItem("role") === "cmca" ||
      localStorage.getItem("role") === "baca" ||
      localStorage.getItem("role") === "hr"
    ) {
      this.getSpocAdmin();
    } else {
      this.getSpoc();
    }
    EventBus.$on("reload-leads-EWB", () => {
      this.analyzePagination(this.currentTablePage);
    });
    EventBus.$on("reloadEntireData", () => {
      this.getSpoc();
    });
  },
  data() {
    return {
      dropdownKey: 0,
      TableData: [],
      countsleads: 0,
      dateRange: {
        startDate: "",
        endDate: "",
      },
      nextCallRange: {
        startDate: "",
        endDate: "",
      },
      nextCall: {
        startDate: "",
        endDate: "",
      },
      lastCall: {
        startDate: "",
        endDate: "",
      },
      settings: {
        maxScrollbarLength: 60,
      },
      spocLogged: false,
      canIdWidth: "100px",
      SpocPrefilled: "",
      email_sent_status: "",
      agreement_sent_status: "",
      spocCityFirst: "",
      batch_number: "",
      nameAutocomplete: [],
      initSelected: undefined,
      initSelectedBDE: [],
      comments: "",
      initSelectedCourses: [],
      initSelectedLevels: [],
      latest_batch_code_cpa: 49,
      latest_batch_code_cma: 40,
      batch_code_category: "",
      batch_code_categories: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
             {
              id: "CPA-US",
              label: "CPA-US",
            },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "IIMI-BA",
              label: "IIMI-BA",
            },
            {
              id: "IIMI-AA",
              label: "IIMI-AA",
            },
            {
              id: "XLRI-HR",
              label: "XLRI-HR",
            },
            {
              id: "XLRI-SH",
              label: "XLRI-SH",
            },
            // {
            //   id: "RPO",
            //   label: "RPO",
            // },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "USP",
              label: "USP",
            },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "DA",
            //   label: "DA"
            // },
            // {
            //   id: "RPA",
            //   label: "RPA"
            // },
            // {
            //   id: "FOF(O)",
            //   label: "FOF(O)"
            // },
            // {
            //   id: "WCBA",
            //   label: "WCBA"
            // },
            // {
            //   id: "NO-BA",
            //   label: "NO-BA"
            // },
            // {
            //   id: "MAYBE",
            //   label: "MAYBE"
            // }
          ],
        },
      ],
      login_spoc_id:"",
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2",
                },
                {
                  id: "L3",
                  label: "L3",
                },
                {
                  id: "L4",
                  label: "L4",
                },
                {
                  id: "L6",
                  label: "L6",
                },
                {
                  id: "L5",
                  label: "L5",
                },
                {
                  id: "L3+",
                  label: "L3+",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                {
                  id: "L4-",
                  label: "L4-",
                },
                {
                  id: "L1",
                  label: "L1",
                },
              ],
            },
            {
              id: "M7",
              label: "M7",
            },
            {
              id: "N/A",
              label: "N/A",
            },
            {
              id: "DND",
              label: "DND",
            },
          ],
        },
      ],
      treeDatafocus: [
        {
          id: "delays",
          label: "Delays",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "waiting",
          label: "Waiting",
        },
        {
          id: "untapped",
          label: "Untapped",
        },
      ],
      tablelinks: 0,
      currentTablePage: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      designation_search: "",
      searchIn: {
        id: "",
        name: "",
      },
      spoc_city: "",
      rawBde: [],
      rawcities: [],
      infosearch: [],
      SpocCityPrefilled: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0,
      mwb_names: [],
    };
  },
  watch: {
    initSelectedBDE: function() {
      this.mergeAllSearch();
    },
    initSelected: function() {
      this.mergeAllSearch();
    },
    initSelectedCourses: function() {
      this.mergeAllSearch();
    },
    initSelectedLevels: function() {
      this.mergeAllSearch();
    },
    currentTablePage: async function(val) {
      this.analyzePagination(val);
    },
    infosearch: function() {
      this.mergeAllSearch();
    },
  },
  methods: {
    generateBatchDropdown() {
      let batch_codes = [];
      let batch_number = 0;
      if (this.pageCourse === "CPA") {
        batch_number = this.latest_batch_code_cpa;
      } else if (this.pageCourse === "CMA") {
        batch_number = this.latest_batch_code_cma;
      }

      for (let index = 0; index < batch_number; index++) {
        let current_number = index + 1;
        if (current_number.toString().length === 1) {
          current_number = `0${current_number}`;
        }
        let batch = `${this.pageCourse}-M7-${current_number}`;
        batch_codes.push(batch);
      }
      batch_codes.push(
        `${this.pageCourse}-M7-EE`,
        `${this.pageCourse}-M7-BB`,
        `${this.pageCourse}-M7-AA`,
        `${this.pageCourse}-M7-WW`
      );
      this.batch_code_categories = batch_codes;
    },
    getSpoc() {
      this.initSelectedBDE = [];
      // let url = `${constants.SERVER_API}getSpocs`;
      let url = ``;

      if (localStorage.getItem("role") === "cm1") {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("spocs", response.data.spocs);
          this.rawBde = [];
          let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          //   console.log(unique, "Unique");
          this.SpocCityPrefilled = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.SpocCityPrefilled.push(key.charAt(0).toUpperCase());
            }
          }
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.searchIn.id) {
              usercity = spoc.city;
              this.spoc_city = spoc.city;
              this.SpocPrefilled = spoc.city.charAt(0).toUpperCase();
            }
          });
          response.data.spocs.forEach((spoc) => {
            if (spoc.city === usercity) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.full_name,
              label: data.full_name,
            };
            this.treeDataBDE[0].children.push(obj);
           
          });
          if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "exed"
          ) {
            this.initSelectedBDE.push("All");
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                // if (bdechid.label === server_spoc.city) {
                //   var obj = {
                //     id: server_spoc.full_name,
                //     label: server_spoc.full_name,
                //   };
                //   bdechid.children.push(obj);
                // }
                if (bdechid.label === server_spoc.city) {
                  if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
                    let obj = {
                    id: server_spoc.full_name,
                    label: server_spoc.full_name,
                  };
                  bdechid.children.push(obj);
                  }
                  else{
                    if(server_spoc.full_name.substring(0,1) != 'X'){
                      let obj = {
                        id: server_spoc.full_name,
                        label: server_spoc.full_name,
                      };
                      bdechid.children.push(obj);
                      }
                  }
                
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    analyzePagination(val) {
      if (this.currentTablePage === 1) {
        this.search_API(1);
      } else {
        this.search_API(val);
      }
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.value1 = "";
      this.value2 = "";
      this.designation_search = "";
      this.value3 = "";
      this.value4 = "";
      this.comments = "";
      this.agreement_sent_status = "";
      this.batch_code_category = "";
      this.email_sent_status = "";
      this.batch_number = "";
      this.value5 = "";
      this.infosearch = [];
      this.initSelected = "";
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "fi1" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.getSpocAdmin();
      } else {
        this.getSpoc();
      }
    },
    removechip(chip) {
      // console.log(chip);
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "fi1" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.treeDataBDE[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      this.treeDataCourses[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevels[0].children.forEach((child) => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach((chi) => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      }
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc" ||
              localStorage.getItem("role") === "ac1" ||
              localStorage.getItem("role") === "ac2" ||
              localStorage.getItem("role") === "fe" ||
              localStorage.getItem("role") === "cmca" ||
              localStorage.getItem("role") === "baca" ||
              localStorage.getItem("role") === "hr"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(
                  this.initSelectedBDE.indexOf(city),
                  1
                );
                this.treeDataBDE[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(
              this.initSelectedCourses.indexOf(chip),
              1
            );
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach((child) => {
              child.children.forEach((chi) => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(chip),
              1
            );
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach((child) => {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label === chip) {
                    childProperty = child.label;
                  }
                });
              }
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach((child) => {
              if (child.label === childProperty) {
                if ("children" in child) {
                  child.children.forEach((chi) => {
                    if (chi.label !== chip) {
                      this.initSelectedLevels.push(chi.label);
                    }
                  });
                }
              }
            });
          }
      }
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          vm.mwb_names = response.data.map((lead) => lead.person_name);
          loading(false);
        });
    }, 350),
    search() {
      this.dropdownKey = Math.random()
      this.search_API();
    },
    async search_API(page) {
      this.$vs.loading();
      /* let focus = this.initSelected;
      if (this.initSelected !== null && this.initSelected !== undefined) {
        focus = this.initSelected.toLowerCase();
      } */
      let bde = [];
      let courses = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      /* this.initSelectedLevels.forEach(sort => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach(child => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach(child => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      }); */
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      //   let visitors = "";
      //   let net_enquiry = "";
      //   let eligibility = "";
      //   let reference = "";
      //   let loan = "";
      //   let corporate = "";
      //   let university = "";
      //   this.infosearch.forEach(value => {
      //     switch (value) {
      //       case "Visitors":
      //         visitors = "Yes";
      //         break;
      //       case "Net Enquiry":
      //         net_enquiry = "Yes";
      //         break;
      //       case "Eligibility":
      //         eligibility = "Yes";
      //         break;
      //       case "Reference":
      //         reference = "Yes";
      //         break;
      //       case "Loan":
      //         loan = "Yes";
      //         break;
      //       case "Corporate":
      //         corporate = "Yes";
      //         break;
      //       case "University":
      //         university = "Yes";
      //         break;
      //     }
      //   });
      let obj = {
        // courses: courses.join(),
        // my_focus: focus,
        spoc_ids: bdeid.join(),
        course: this.pageCourse,
        name: this.value1,
        identity: this.value5,
        company: this.value2,
        designation: this.designation_search,
        agreement_status: "",
        email_sent_status: "",
        mobile: this.value3,
        batch_code_category: this.batch_code_category,
        email: this.value4,
        comments: this.comments,
        // batch_number: this.batch_number,
        from_date: "",
        to_date: "",
        next_call_from_date: "",
        next_call_to_date: "",
        // last_call_from_date: "",
        // last_call_to_date: "",
        page: page,
        // next_call_from_date: "",
        // last_call_to_date: "",
      };
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }

      if (
        this.nextCallRange.startDate !== "" &&
        this.nextCallRange.endDate !== ""
      ) {
        obj.next_call_from_date = this.datatoTimestamp(
          this.nextCallRange.startDate
        );
        obj.next_call_to_date = this.datatoTimestamp(
          this.nextCallRange.endDate
        );
      }

      // if (this.nextCall.startDate !== "" && this.nextCall.endDate !== "") {
      //   obj.next_call_from_date = this.datatoTimestamp(this.nextCall.startDate);
      //   obj.next_call_to_date = this.datatoTimestamp(this.nextCall.endDate);
      // }

      // if (this.lastCall.startDate !== "" && this.lastCall.endDate !== "") {
      //   obj.last_call_from_date = this.datatoTimestamp(this.lastCall.startDate);
      //   obj.last_call_to_date = this.datatoTimestamp(this.lastCall.endDate);
      // }

      if (this.agreement_sent_status === "Incomplete") {
        obj.agreement_status = 0;
      } else if (this.agreement_sent_status === "Complete") {
        obj.agreement_status = 1;
      }

      if (this.email_sent_status === "Sent") {
        obj.email_sent_status = 1;
      } else if (this.email_sent_status === "Not Sent") {
        obj.email_sent_status = 0;
      }

      console.log(obj);
      axios
        .get(`${constants.SERVER_API}searchEnrollments`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.TableData = response.data.data;
          this.currentTablePage = response.data.current_page;
          this.tablelinks = response.data.last_page;
          this.countsleads = response.data.total;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.searchGap {
  margin-bottom: 1%;
}
</style>
